<template>
  <div>
    <b-card no-body>

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="6">
            <h4 class="mt-1">
              {{ accountId || '--' }}
            </h4>

          </b-col>
          <b-col cols="12" md="3">
            <div class="mt-1" />
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="3">
            <div class="d-flex align-items-center justify-content-end">

              <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm"
                label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

            </div>
          </b-col>
        </b-row>

      </div>

    </b-card>

    <b-overlay :show="loading" rounded="sm">

      <b-alert v-if="products && !loading && products.length < 1" variant="info" show class="m-2">
        <div class="alert-body">
          <span><strong>Empty </strong> This list is empty </span>
        </div>
      </b-alert>

      <b-card v-for="(item, index) in products" class="card-congratulation-medal" :key="item.id">
        <b-overlay :show="isLoadingItem == item._id || isLoadingItem == index" rounded="sm">
          <b-row>
            <b-col cols="12">
              <h3>
                {{ item.lastUn }}

              </h3>

              <b-row>
                <b-col cols="6">
                  <strong>Mode : </strong>

                  <span class="form-inline d-inline-block">
                    <select class="form-control form-control-inline" @change="onStatusChange($event, item.id)">
                      <option value="notify" :selected="item.mode == 'notify'">
                        Notify Only

                      </option>
                      <option value="fix" :selected="item.mode == 'fix'">
                        Auto upate/fix
                      </option>

                      <option value="ignore" :selected="item.mode == 'ignore'">
                        Ignore
                      </option>

                    </select>
                  </span>


                </b-col>
                <b-col cols="6" class="text-right">
                  <b-button v-b-modal="'modal-' + index" variant="outline-primary">
                    Last Notification
                  </b-button>

                  <!-- basic modal -->
                  <b-modal :id="'modal-' + index" title="Last Notification Modal" ok-only ok-title="Accept">

                    <pre>{{ '---' }}</pre>

                  </b-modal>

                </b-col>
              </b-row>

              <hr>
              <b-row class="mt-1">
                <b-col cols="8" class="text-end text-left">

                  <strong>Actions : </strong>

                  <b-button v-if="item.state !== 'MONITORED'" variant="warning" size="sm" class="text-warning mr-1 mb-1"
                    @click="onWatchAgain(item.id, index)">
                    <feather-icon icon="RefreshCwIcon" class="mr-50" />
                    Monitor</b-button>


                  <b-button variant="warning" size="sm" class="text-warning mr-1 mb-1"
                    @click="onProcess(item.id, index, 'data')">
                    <feather-icon icon="RefreshCwIcon" class="mr-50" />
                    Fix Data & Relashionship </b-button>

                  <b-button variant="warning" size="sm" class="text-warning mr-1 mb-1"
                    @click="onProcess(item.id, index, 'relationship')">
                    <feather-icon icon="RefreshCwIcon" class="mr-50" />
                    Fix Relashionship
                  </b-button>

                </b-col>
                <b-col cols="4" class="text-end text-right">
                  <router-link :to="{ name: 'edit-monitored-listing', params: { itemId: item.id } }">
                    <b-button variant="primary" size="sm" class="btn-tour-finish mr-1 mb-1">
                      Edit
                    </b-button>
                  </router-link>

                  <b-button variant="danger" size="sm" class="btn-tour-finish mr-1 mb-1" @click="remove(item.id)">
                    Remove
                  </b-button>

                </b-col>
              </b-row>
              <hr>
              <div>
                <strong>Details : </strong>
                <b-badge :variant="'dark'" class="mr-1 mb-1">
                  <b>ASIN : </b>{{ item.asin }}
                </b-badge>

                <b-badge :variant="'dark'" class="mr-1 mb-1">
                  <b>SKU : </b>{{ item.sku }}
                </b-badge>

                <b-badge :variant="'dark'" class="mr-1 mb-1">
                  <b>PARENT SKU : </b>{{ item.parentSku }}
                </b-badge>

                <b-badge :variant="'dark'" class="mr-1 mb-1">
                  <b>PARENT ASIN : </b>{{ item.parentAsin }}
                </b-badge>

              </div>
              <hr>
              <strong>Status : </strong>

              {{ item.state }} ---
              <span class="text-left">
                Last checking ago :
                <b-badge :variant="item.authorizedAgo > 330 ? 'danger' : 'success'">
                  {{ item.lastCheckingAgo }}
                </b-badge>
              </span>

              <hr>

              <div>
                <strong>Process History : </strong>

                <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
                {{ item.toProcessItems.length }}
                <b-form-checkbox v-if="item.toProcessItems.length || 1" v-model="item.detailsShowing"
                  @change="toggleDetails">
                  <span>{{ item.detailsShowing ? 'Hide' : 'Show' }} </span>
                </b-form-checkbox>

                <!-- full detail on click -->
                <template v-if="item.detailsShowing">
                  <b-overlay :show="isChildLoadingItem == item._id" rounded="sm">
                    <b-card no-body>

                      <b-table :items="item.toProcessItems" :fields="childrenFields" :tbody-tr-class="'table-primary'"
                        striped responsive size="sm" class="mb-0">

                        <template #cell(processingSummary)="data">


                          <b-button v-b-modal="'modal-' + data.item._id" variant="outline-primary">
                            Summary
                          </b-button>

                          <!-- basic modal -->
                          <b-modal :id="'modal-' + data.item.id" title="Summary Modal" ok-only ok-title="Accept">

                            <pre>{{ data.item.processingSummary }}</pre>

                          </b-modal>

                          <b-button v-b-modal="'modal-report-' + data.item.id" variant="outline-primary">
                            Report
                          </b-button>

                          <!-- basic modal -->
                          <b-modal :id="'modal-report-' + data.item.id" title="Report Modal" ok-only ok-title="Accept">
                            <pre>{{ data.item.processingReport }}</pre>
                          </b-modal>


                        </template>

                        <template #cell(lastFeedStatus)="data">

                          <div>{{ data.item.lastFeedStatus }} </div>

                          <b-button v-if="data.item.lastFeedStatus != 'DONE'"
                            @click="onGetFeed(data.item._id, index, data.item.monitoredItemId)">
                            Refresh
                          </b-button>

                        </template>

                      </b-table>

                      <b-button class="mt-1" size="sm" variant="outline-secondary" v-model="item.detailsShowing"
                        @click="toggleDetails">
                        Hide items
                      </b-button>
                    </b-card>
                  </b-overlay>
                </template>

              </div>

            </b-col>

          </b-row>
        </b-overlay>
      </b-card>
    </b-overlay>

  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BAlert, BFormGroup, BFormCheckbox, BBadge, BButton, BPagination, VBTooltip, BOverlay, BRow, BCol, BFormInput, BFormSelect, BInputGroup, BInputGroupAppend, VBPopover,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BAlert,
    BCardCode,
    BTable,
    BButton,
    BPagination,
    BOverlay,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    vSelect,
    BFormCheckbox,
    BFormGroup,
    ToastificationContent,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  data() {
    return {
      loading: false,
      isLoadingItem: null,
      isChildLoadingItem: null,
      currentPage: 1,
      accountId: null,
      searchQuery: null,
      statusFilter: null,
      totalItems: null,
      totalUnprocessedIsMonitored: null,
      typeFilter: null,
      filter: null,
      filterOn: ['asin', 'sku', 'itemName'],
      statusOptions: [
        'success',
        'error',
        'warning',
      ],
      typeOptions: [
        'orders',
        'order_items',
        'reports',
        'report_document',
        'fba_inventory',
      ],
      products: null,
      perPage: 25,
      fields: ['id', 'asin', 'sku', 'parentSku', 'parentAsin', 'itemName', { toProcessItems: 'Processed' }, 'state', 'lastCheckingAgo', 'actions'],
      childrenFields: ['feedId', 'type', 'state', 'lastFeedStatus', { 'triggeredBy': 'by' }, 'processingSummary', 'createdAt'],
      current: 1,
      rows: 0,
    }
  },
  beforeMount() {

  },

  mounted() {
    this.accountId = this.$route.params.accountId
    this.loadMonitoredProducts(this.$route.params.accountId, 1)
  },

  methods: {

    handlePageChange(value) {
      this.page = value
      this.loadMonitoredProducts(this.accountId, this.page)
    },

    loadMonitoredProducts(accountId, page = 1) {
      const filters = {}

      if (this.typeFilter) {
        filters.key = this.typeFilter
      }

      if (this.statusFilter) {
        filters.status = this.statusFilter
      }

      this.loading = true
      this.$store.dispatch('amzSellingPartnerAccount/fetchMonitoredItems', { accountId, page, ...filters })
        .then(result => {
          this.products = result.data.docs
          this.totalItems = result.data.totalItems
          this.currentPage = result.data.page
          this.rows = result.data.totalDocs
        },
          error => {
            this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
          }).finally(() => {
            this.loading = false
          })
    },
    loadItem(itemId, index) {
      this.isLoadingItem = itemId
      this.$store.dispatch('amzSellingPartnerAccount/getMonitoredItem', itemId)
        .then(result => {
          console.log(this.products[index])
          this.$set(this.products[index], 'toProcessItems', [])

          setTimeout(() => {
            this.$set(this.products[index], 'toProcessItems', result.data.toProcessItems)

          })
          //this.$set(this.products, index, result.data)
        },
          error => {
            this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
          }).finally(() => {
            console.log()
            this.isLoadingItem = null
          })
    },
    /**
     * 
     * @param {string} id 
     */
    remove(id) {

      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger ',
          cancelButton: 'btn btn-outline-primary  ml-1',
        },
        buttonsStyling: false,

      }).then(result => {

        if (result.value === true) {
          this.loading = true

          this.$store.dispatch('amzSellingPartnerAccount/removeMonitoredItem', id).then(
            () => {
              this.showToast('Success', 'Removed with success', 'success')
              this.loadMonitoredProducts(this.accountId, this.current)
            },
            error => {
              console.log(err)
              this.showToast('Error', error.response?.data?.message || error?.message || 'Error while removing item', 'danger')
            },
          )
        }
      },
        error => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
    },

    onWatchAgain(id, index) {
      const data = {
        id,
        state: 'MONITORED',
      }
      this.loading = true
      this.$store.dispatch('amzSellingPartnerAccount/updateMonitoredItem', data)
        .then(result => {
          this.$set(this.products, index, result.data)
          this.showToast('Success', 'Item was updated successfully', 'success')
        },
          error => {
            this.showToast('Error', error.response?.data?.message || error?.message || 'Error while updating item', 'danger')
          }).finally(() => {
            this.loading = false
          })
    },
    onStatusChange($event, id) {

      const data = {
        id,
        mode: $event.target.value,
      };
      this.isLoadingItem = id
      this.$store.dispatch("amzSellingPartnerAccount/updateMonitoredItem", data).then(
        () => {
          this.showToast("Success", "Item was updated successfully", "success");
        },
        (error) => {
          this.showToast(
            "Error",
            error.response?.data?.message ||
            error?.message ||
            "Error while updating item",
            "danger"
          );
        }
      ).finally(() => {
        this.isLoadingItem = null
      });
    },
    onProcess(id, index, type) {
      const data = {
        id,
        type: type,
      }
      this.isLoadingItem = id
      this.$store.dispatch('amzSellingPartnerAccount/processMonitoredItem', data)
        .then(result => {
          this.loadItem(id, index)
          this.showToast('Success', result.data.message || 'Item was refreshed successfully', 'success')
        },
          error => {
            this.showToast('Error', error.response?.data?.message || error?.message || 'Error while updating item', 'danger')
          }).finally(() => {
            this.isLoadingItem = null
          })
    },
    onGetFeed(id, index, parentId) {

      this.isChildLoadingItem = parentId
      this.$store.dispatch('amzSellingPartnerAccount/getMonitoredItemFeed', id)
        .then(result => {
          console.log(' onGetFeed : ', result.data.monitoredItemId, index)
          this.loadItem(parentId, index)
          this.showToast('Success', result.data.message || 'Item was updated successfully', 'success')
        },
          error => {
            this.showToast('Error', error.response?.data?.message || error?.message || 'Error while updating item', 'danger')
          }).finally(() => {
            this.isChildLoadingItem = null
          })
    },
    toggleDetails() {

    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },

  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 120px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
